<template>
	<layouts-basic>
		<div class="pb-7">
			<h3 class="ls-tight fw-bolder h3">Establecer ubicación</h3>
			<p class="text-sm text-muted">Buscar por dirección o ubicación actual.</p>
		</div>
		<form id="address-form" class="vstack gap-3" @submit.prevent="submit">
			<div class="alert alert-warning p-2 mt-3 text-sm fw-bold text-center" role="alert" v-if="mandatory">
				La ubicación es requerida para continuar.
			</div>
			<div>
				<div class="form-floating" :class="{'is-invalid': validation.street_number.$error}">
					<GMapAutocomplete
						placeholder="Buscá tu dirección"
						@place_changed="setPlace"
						id="street_number"
						:options="{ type: ['geocode'], componentRestrictions: { country: countries } }"
						class="form-control autocomplete-field"
						:class="{'is-invalid': validation.street_number.$error}"
						v-focus>
					</GMapAutocomplete>
					<label for="street_number">Dirección</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.street_number.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div v-show="form.geolocation.lat">
				<GMapMap
					:center="center"
					:zoom="zoom"
					:options="{ disableDefaultUI: true }"
					class="border rounded-2 overflow-hidden"
					map-type-id="roadmap"
					style="width: 100%; height: 300px;">
					<GMapMarker
						:icon="{ url: require('@/assets/img/icons/marker.png'), scaledSize: { width: 46, height: 46 } }"
						:position="form.geolocation"
						:draggable="true"
						@dragend="updateGeolocation"
						v-if="hasAddress"
					/>
				</GMapMap>
				<div class="d-flex justify-content-between mt-3">
					<common-message-info text="Podés arrastrar el pin." wrapperClass="text-sm lh-xs" />
					<a href="javascript:void(0);" class="link-primary text-sm" @click="advance = !advance;">Carga manual</a>
				</div>
			</div>
			<div class="row g-3" v-if="advance">
				<div class="col-12 col-md-8 col-lg-7 col-xl-6">
					<div class="form-floating" :class="{'is-invalid': validation.route.$error}">
						<input type="text" id="route" v-model="form.route" class="form-control" :class="{'is-invalid': validation.route.$error}" placeholder="Ingresá el número de departamento, interno de casa, etc. (opcional)" maxlength="20">
						<label for="route">Calle</label>
					</div>
					<span class="mt-1 invalid-feedback" v-for="error of validation.route.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
				<div class="col-12 col-md-4 col-lg-5 col-xl-6">
					<div class="form-floating" :class="{'is-invalid': validation.street_number.$error}">
						<input type="text" id="street_number" v-model="form.street_number" class="form-control" :class="{'is-invalid': validation.street_number.$error}" placeholder="Ingresá el número de departamento, interno de casa, etc. (opcional)" maxlength="20">
						<label for="street_number">Número (opcional)</label>
					</div>
					<span class="mt-1 invalid-feedback" v-for="error of validation.street_number.$errors" :key="error.$uid">{{ error.$message }}</span>
				</div>
			</div>
			<div>
				<div class="form-floating" :class="{'is-invalid': validation.division.$error}">
					<input type="text" id="division" v-model="form.division" class="form-control" :class="{'is-invalid': validation.division.$error}" placeholder="Ingresá el número de departamento, interno de casa, etc. (opcional)" maxlength="20">
					<label for="division">Dpto, casa (opcional)</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.division.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div v-show="hasUser">
				<div class="form-floating" :class="{'is-invalid': validation.description.$error}">
					<input type="text" id="description" v-model="form.description" class="form-control" :class="{'is-invalid': validation.description.$error}" placeholder="Ingresá una descripción" maxlength="20">
					<label for="description">Descripción de la ubicación... ¿casa?, ¿oficina?</label>
				</div>
				<span class="mt-1 invalid-feedback" v-for="error of validation.description.$errors" :key="error.$uid">{{ error.$message }}</span>
			</div>
			<div>
				<button type="submit" class="btn btn-primary w-100" :disabled="submitting">Confirmar dirección</button>
			</div>
			<div v-if="hasGeolocation">
				<button type="button" class="btn btn-outline-warning w-100" @click="userCurrentLocation">Usar ubicación actual</button>
			</div>
			<div v-if="hasUser">
				<router-link :to="{name: 'location.index'}" class="btn btn-outline-dark w-100">Mis direcciones</router-link>
			</div>
			<div v-if="!mandatory">
				<button type="button" class="btn btn-neutral w-100" @click="redirectBack">Cancelar</button>
			</div>
		</form>
		<div class="mt-6 text-center" v-if="!hasUser">
			¿Ya tienes una cuenta? <router-link :to="{ name: 'identify.index' }" class="text-secondary fw-semibold">Ingresar</router-link>
		</div>
	</layouts-basic>
</template>

<script>
	import { ref, reactive, computed, watch } from 'vue';
	import store from '@/store';
	import { useRoute } from 'vue-router';
	import useVuelidate from '@vuelidate/core';
	import { helpers } from '@vuelidate/validators';
	import { required, maxLength } from '@/helpers/i18n/validators';
	import _ from 'lodash';
	import VueScrollTo from 'vue-scrollto';
	import { useToast } from 'vue-toastification';
	import composableRedirect from '@/composables/redirect';
	import composableForm from '@/composables/form';
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { redirectBack, redirectNext } = composableRedirect();
			const { submitting, validationExternal, catchError } = composableForm();
			const { current_store } = composableStore();
			const { query: { mandatory }} = useRoute();
			const toast = useToast();
			const hasUser = computed(() => store.getters['auth/hasUser']);
			const hasAddress = ref(false);
			const advance = ref(false);
			const zoom = computed(() => (hasAddress.value) ? 16 : 6);
			const center = ref({ lat: -35.2, lng: -65.2 });
			const countries = computed(() => {
				if(current_store.value?.country) return [ current_store.value?.country ];
				else return process.env.VUE_APP_COUNTRIES.split(',');
			});
			const form = reactive({
				id: null,
				description: null,
				country: null,
				administrative_area_level_1: null,
				administrative_area_level_2: null,
				administrative_area_level_3: null,
				locality: null,
				sublocality: null,
				route: null,
				street_number: null,
				postal_code: null,
				division: null,
				geolocation: {
					lat: null,
					lng: null
				}
			});

			const hasGeolocation = ('geolocation' in navigator);

			const addressText = computed(() => {
				var address_array = [];

				if(form.route) address_array.push(form.route);
				if(form.street_number) address_array.push(`Nro. ${form.street_number}`);

				return (address_array.length > 0) ? address_array.join(' ') : null;
			});

			watch(addressText, (newValue) => document.querySelector('.autocomplete-field').value = newValue);

			const setPlace = (result, updateGeolocation = true) => {
				_.forEach(result.address_components, function(component) { _.forEach(component.types, function(type) { if(type in form) form[type] = component.short_name; }); });

				if(updateGeolocation) {
					form.geolocation.lat = result.geometry.location.lat();
					form.geolocation.lng = result.geometry.location.lng();

					center.value.lat = result.geometry.location.lat();
					center.value.lng = result.geometry.location.lng();

					VueScrollTo.scrollTo('#address-form', 100, {offset: () => { return -20; }});
				}

				if(!form.route) advance.value = true;

				hasAddress.value = true;
			}

			const setPlaceBylatLng = (latLng) => {
				var maps = new window.google.maps.Geocoder;
				maps.geocode({location: latLng}, (results) => {
					var record = _.find(results, (item) => { return item.types.includes('street_address'); });
					if(!record) return toast.error('No se encontró una dirección válida.');

					setPlace(record, false);
				});
			}

			const updateGeolocation = (data) => {
				setPlaceBylatLng({ lat: data.latLng.lat(), lng: data.latLng.lng() });
			}

			const userCurrentLocation = () => {
				window.navigator.geolocation.getCurrentPosition(
					(data) => {
						setPlaceBylatLng({ lat: data.coords.latitude, lng: data.coords.longitude });
					}, () => toast.error('No es posible acceder a tu ubicación.'));
			}

			const validateAddress = () => {
				if(!form.route) return false;
				if(!form.street_number && !countries.value.includes('PY')) return false;
				if(!form.geolocation.lat) return false;
				if(!form.geolocation.lng) return false;

				return true;
			}

			const validation = useVuelidate({
				description: {
					maxLength: maxLength(20)
				},
				route: {
					required,
					maxLength: maxLength(150)
				},
				street_number: {
					validateAddress: helpers.withMessage('Seleccione una dirección válida.', validateAddress)
				},
				division: {
					maxLength: maxLength(20)
				}
			}, form);

			async function submit() {
				validationExternal.value = [];
				if(!await validation.value.$validate()) return;

				//Formatea el teléfono
				submitting.value = true;
				store.dispatch('location/createAddress', form).then(redirectNext).catch(catchError);
			}

			return { redirectBack, current_store, mandatory, countries, hasUser, hasAddress, advance, form, addressText, zoom, center, hasGeolocation, setPlace, updateGeolocation, userCurrentLocation, validation, validationExternal, submitting, submit };
		}
	}
</script>

<style>
	.map-container {
		position: relative;
	}

	.map-container .map-marker {
		position: absolute;
		left: calc(50% - 20px);
		top: calc(50% - 40px);
		height: 40px;
		width: 40px;
		z-index: 1;
	}

	.pac-container {
		z-index: 2 !important;
		font-family: inherit;
		font-size: 1rem;
		color: #464E5F;
		border-width: 0px;
		box-shadow: none;
		padding: 0px 0px 10px 0px;
		margin: 5px 0px 0px 0px;
		background-color: #ffffff;
		border-radius: .3rem !important;
		border-width: 1px !important;
		border: 0 solid #e7eaf0;
	}

	.pac-container:before { 
		content: "Seleccioná una opción...";
		display: block;
		font-family: inherit;
		font-size: 1rem;
		text-align: left;
		color: #FFA800;
		width: 100%;
		padding: 8px 10px;
		border-width: 0px 0px 1px 0px;
		border-style: solid;
		border-color: #e2e5ec;
	}

	.pac-item {
		border-width: 0px;
		cursor: pointer;
		margin: 0px;
		padding: 5px 10px;
	}

	.pac-item:not(:last-child) {
		border-width: 0px 0px 1px 0px;
		border-style: solid;
		border-color: #e2e5ec;
	}

	.pac-icon {
		display: none;
		padding: 0px 9px;
	}
</style>